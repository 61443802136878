<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader">
              <SelectFilter
                :options="classifyOptions"
                :label="$t('labels.classify')"
                :placeholder="$t('labels.classify')"
                name="type"
                sort-name="type"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <!-- <th role="columnheader">
              <SelectWarehouse
                :label="$t('labels.warehouse')"
                :placeholder="$t('labels.warehouse')"
                name="id_warehouse"
                sort-name="warehouse_code"
               
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th> -->
            <th role="columnheader">
              <DateRangeFilter
                :label="$t('labels.date')"
                :placeholder="$t('labels.date')"
                name="dates"
                sort-name="dates"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.note')"
                :placeholder="$t('labels.note')"
                name="note"
                sort-name="note"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :options="yesNoOptions"
                :label="$t('labels.is_allow_order_confirm')"
                :placeholder="$t('labels.is_allow_order_confirm')"
                name="is_allow_order_confirm"
                sort-name="is_allow_order_confirm"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :options="yesNoOptions"
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                name="status"
                sort-name="status"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center">
              <v-btn color="primary" @click="showCreateDialog">
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in items"
            :key="`p_${key}_${item.id}`"
            class="text-center"
          >
            <td>{{ $t(`labels.day_off_type_${item.type}`) }}</td>
            <!-- <td>{{ item.warehouse_code }}</td> -->
            <td>{{ formatDateTime(item.date, "DD/MM/YYYY") }}</td>
            <td>{{ item.note }}</td>
            <td>{{ item.is_allow_order_confirm ? "Yes" : "No" }}</td>
            <td>{{ item.status ? "Yes" : "No" }}</td>
            <td>
              <v-btn small color="warning" @click="showUpdateDialog(item)">
                {{ $t("labels.edit") }}
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12" sm="4">
        <!-- <v-btn color="info">{{ $t('labels.excel') }}</v-btn> -->
      </v-col>
      <v-col cols="12" sm="4">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="5"
        ></v-pagination>
      </v-col>
      <v-col cols="12" sm="4" class="text-right"> </v-col>
    </v-row>

    <v-dialog v-model="updateDialog" persistent max-width="640px">
      <DayOffDialog
        v-if="updateDialog"
        :updating-item="updatingItem"
        @refreshData="getList"
        @cancel="hideCreateDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { DAY_OFF_TYPES, YES_NO_OPTIONS } from "@/libs/const";
import { httpClient } from "@/libs/http";
import { debounce, formatDateTime, formatNumber } from "@/libs/helpers";

export default {
  name: "DayOff",
  components: {
    // SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    DayOffDialog: () => import("@/components/administration/DayOffDialog"),
  },
  data: () => ({
    page: 1,
    totalPage: 1,
    items: [],
    viewOption: "view0",
    filters: {},
    yesNoOptions: [...YES_NO_OPTIONS],
    classifyOptions: [...DAY_OFF_TYPES],
    isLoading: false,
    updateDialog: false,
    updatingItem: {},
    sum: {},
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    formatDateTime,
    formatNumber,
    showCreateDialog() {
      this.updatingItem = {};
      this.updateDialog = true;
    },
    hideCreateDialog() {
      this.updatingItem = {};
      this.updateDialog = false;
    },
    showUpdateDialog(item) {
      this.updatingItem = { ...item };
      this.updateDialog = true;
    },
    setViewOption(mode) {
      this.viewOption = mode;
    },
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    getList: debounce(function () {
      httpClient
        .post("/common/v1/day-off-list", {
          ...this.filters,
          page: this.page,
          viewMode: this.viewOption,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
          this.sum = { ...data.sum };
        });
    }, 500),
  },
};
</script>
